import React from 'react';
import classNames from 'classnames';
import { observer, inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Menu from './Menu';
import Time from './Time';
import StatusBar from './StatusBar';
import Sidebar from './Sidebar';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    minHeight: theme.spacing(4),
    boxShadow: 'none',
    background: 'none',
  },
  rootMobile: {
    marginBottom: 0,
    flexDirection: 'row-reverse',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default inject('yluiStore')(
  observer(({ yluiStore }) => {
    const classes = useStyles();

    return (
      <Box
        color="primary"
        className={classNames(classes.root, {
          [classes.rootMobile]: yluiStore.isMobile,
        })}
      >
        <Menu />
        <StatusBar />
        <Time />
        <Sidebar />
      </Box>
    );
  })
);
