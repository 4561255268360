import { observable, action } from 'mobx';

const loadImage = url => {
  return new Promise(resolve => {
    const img = document.createElement('img');
    img.src = url;
    img.onload = resolve;
  });
};

class StoreWallpaper {
  wallpaper = '';
  @observable wallpaperRealA = '';
  @observable wallpaperRealB = '';
  @observable activeA = true;

  constructor(root) {
    this.root = root;
  }

  @action setWallpaper(url) {
    this.wallpaper = url;
    if (this.activeA) {
      this.wallpaperRealB = url;
      return loadImage(url).then(() => {
        this.activeA = false;
      });
    } else {
      this.wallpaperRealA = url;
      return loadImage(url).then(() => {
        this.activeA = true;
      });
    }
  }
}

export default StoreWallpaper;
