import React from 'react';
import { observer, inject } from 'mobx-react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ContentContainer from '../../parts/ContentContainer';

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      transition: 'all 0.2s',
      overflow: 'hidden',
      position: 'absolute',
      userSelect: 'none',
      zIndex: 100,
      height: '100%',
    },
    rootPC: {
      width: ({ width }) => width,
      top: 0,
      right: 0,
    },
    rootMobile: {
      width: '100%',
      bottom: 0,
    },
    rootMinPC: {
      right: ({ width }) => -width,
    },
    rootMinMobile: {
      bottom: 'calc(-100% - 40px)',
    },
  };
});

const Sidebar = inject('yluiStore')(
  observer(({ yluiStore }) => {
    const width = 320;
    const classes = useStyles({ width, isMobile: yluiStore.isMobile });
    const storeSidebar = yluiStore.storeSidebar;
    return (
      <Box
        className={classNames(classes.root, {
          [classes.rootPC]: !yluiStore.isMobile,
          [classes.rootMobile]: yluiStore.isMobile,
          [classes.rootMinPC]: storeSidebar.min && !yluiStore.isMobile,
          [classes.rootMinMobile]: storeSidebar.min && yluiStore.isMobile,
        })}
        style={{
          ...yluiStore.storeTheme.styleBgSidebar,
          ...yluiStore.storeTheme.styleColorWhite1,
          ...yluiStore.storeTheme.styleBoxShadow,
          zIndex: storeSidebar.zIndex,
        }}
      >
        <ContentContainer>{storeSidebar.content}</ContentContainer>
      </Box>
    );
  })
);

export default Sidebar;
