import React from 'react';
import { Provider, observer } from 'mobx-react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { ShortcutProvider, useShortcut } from '@shopify/react-shortcuts';
import 'animate.css';
import screenfull from 'screenfull';

import Frames from './Frames/index.js';
import AppBar from './AppBar';
import Wallpaper from './Wallpaper/index.js';
import ErrorBoundary from './ErrorBoundary/index.js';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
  },
});

const Main = observer(({ store }) => {
  const { handleShortCut, isMobile, storeWallpaper } = store;
  [1, 2, 3, 4, 5, 6, 7, 8].forEach(t => {
    useShortcut(
      [t + ''],
      () => {
        handleShortCut({
          type: 'toggleWindow',
          data: t,
        });
      },
      {
        held: ['Alt'],
      }
    );
  });
  const classes = useStyles();
  const handleMainClick = React.useCallback(() => {
    if (store.isMobile && screenfull.isEnabled) {
      // screenfull.request();
    }
  }, [store.isMobile]);
  return (
    <Box
      display="flex"
      flexDirection={isMobile ? 'column-reverse' : 'column'}
      className={classNames(classes.root)}
      onClick={handleMainClick}
    >
      <AppBar />
      <Frames />
      <Wallpaper isMobile={isMobile} yluiStoreWallpaper={storeWallpaper} />
    </Box>
  );
});

const App = observer(({ store }) => {
  return (
    <Provider yluiStore={store}>
      <CssBaseline />
      <ShortcutProvider>
        <ErrorBoundary>
          <Main store={store} />
        </ErrorBoundary>
      </ShortcutProvider>
    </Provider>
  );
});

export default App;
