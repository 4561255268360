import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LightTooltip from '../parts/LightTooltip';
// import Icon from '../parts/Icon';
// import iconTime from '../../asset/icon/icons8-clock-48.png';

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      transition: 'all 0.2s',
      minHeight: theme.spacing(4),
      padding: `0 ${theme.spacing(1)}px`,
      position: 'relative',
      userSelect: 'none',
      flexGrow: 0,
      fontWeight: 300,
      fontSize: 12,
    },
  };
});

const Time = inject('yluiStore')(
  observer(({ yluiStore }) => {
    const classes = useStyles();
    const [time, setTime] = useState(new Date());
    useEffect(() => {
      const itv = setInterval(() => {
        setTime(new Date());
      }, 500);
      return () => {
        clearInterval(itv);
      };
    }, []);
    const m = moment(time);
    return (
      <Box
        className={classes.root}
        style={{
          ...yluiStore.storeTheme.styleBgTaskBar,
          ...yluiStore.storeTheme.styleColorWhite1,
        }}
      >
        <LightTooltip
          title={m.format('L')}
          enterDelay={500}
          leaveDelay={200}
          interactive
        >
          <span>{m.format('HH:mm')}</span>
        </LightTooltip>
      </Box>
    );
  })
);

export default Time;
