import { observable, action, computed, reaction } from 'mobx';
import defaultIcon from '../asset/icon/icons8-web-48.png';

class Task {
  root;
  refIframe = null; // 记录iframe的ref
  handleOnClose = () => {};
  sizeNormal = {
    width: 0,
    height: 0,
  };
  positionNormal = {
    top: 0,
    left: 0,
  };
  @observable zIndex;
  @observable content;
  @observable title;
  @observable max;
  @observable min;
  @observable width;
  @observable height;
  @observable top;
  @observable left;
  @observable closeable;
  @observable resizeable;
  @observable dragable;
  @observable countTransitionRestore = 0;
  @computed get actived() {
    return this.root.activeTaskId === this.id;
  }

  @computed get onTransitionRestore() {
    return this.countTransitionRestore > 0;
  }

  constructor({
    root,
    id = 0,
    zIndex = 0,
    content,
    title = '-',
    max = false,
    min = false,
    width = 640,
    height = 480,
    top = 200,
    left = 100,
    closeable = true,
    resizeable = true,
    dragable = true,
    singleton = '', // 单例标记ID
    icon = defaultIcon,
  }) {
    this.root = root;
    this.id = id;
    this.zIndex = zIndex;
    this.content = content;
    this.title = title;
    this.max = max;
    this.min = min;
    this.width = width;
    this.height = height;
    this.top = top;
    this.left = left;
    this.closeable = closeable;
    this.resizeable = resizeable;
    this.dragable = dragable;
    this.singleton = singleton;
    this.icon = icon;
    this.dx = 0;
    this.dy = 0;

    reaction(
      () => this.max,
      () => {
        this.countTransitionRestore++;
        setTimeout(() => {
          this.countTransitionRestore--;
        }, 200);
      }
    );
  }

  @action setSize = ({ width, height }) => {
    const { width: maxWidth, height: maxHeight } = this.root.framesSize;
    this.width = width + this.left > maxWidth ? maxWidth - this.left : width;
    this.height = height + this.top > maxHeight ? maxHeight - this.top : height;
  };

  @action setPosition = ({ top, left }) => {
    this.top = top < 0 ? 0 : top;
    this.left = left < 0 ? 0 : left;
  };

  // 记录drag过程中的位置变化量
  @action drag = ({ dx, dy }) => {
    this.dx += dx;
    this.dy += dy;
  };

  @action dragStop = () => {
    this.setPosition({
      top: (this.top += this.dy),
      left: (this.left += this.dx),
    });
    this.dx = 0;
    this.dy = 0;
  };

  @action _active = () => {
    this.zIndex = this.root.genId();
    this._minimize(false);
  };

  @action active = () => {
    this.root.activeTask(this.id);
  };

  @action minimize = () => {
    this.root.minimizeTask(this.id);
  };

  @action _minimize = (isMin = true) => {
    this.min = isMin;
  };

  @action maximise = () => {
    const { width, height, top, left } = this;
    this.sizeNormal = {
      width,
      height,
    };
    this.positionNormal = {
      top,
      left,
    };
    this.setPosition({
      top: 0,
      left: 0,
    });
    this.setSize({
      width: this.root.framesSize.width,
      height: this.root.framesSize.height,
    });
    this.max = true;
    console.log(this.root.framesSize);
  };

  @action clearMaxWhenResize = () => {
    this.max = false;
  };

  @action restore = () => {
    const { width, height } = this.sizeNormal;
    const { top, left } = this.positionNormal;
    this.setSize({
      width,
      height,
    });
    this.setPosition({
      top,
      left,
    });
    this.max = false;
  };

  @action close = () => {
    this.root.closeTask(this.id);
  };

  onClose(handle) {
    this.handleOnClose = handle;
  }

  _setRefIframe = ref => {
    this.refIframe = ref;
  };
}

export default Task;
