import { observable, action, computed } from 'mobx';

class Menu {
  @observable min = true;
  @observable content = null;
  @observable icon = null;
  @observable width = 240;
  @observable height = 640;
  @computed get actived() {
    return !this.min;
  }
  constructor(root) {
    this.root = root;
  }
  @action setContent = content => {
    this.content = content;
  };
  @action setIcon = icon => {
    this.icon = icon;
  };
  @action active = () => {
    const newIndex = this.root.genId();
    this.zIndex = newIndex;
    this.min = false;
    this.root.storeSidebar.hide();
  };
  @action hide = () => {
    this.min = true;
  };
}

export default Menu;
