import React from 'react';
import { observer, inject } from 'mobx-react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Resizable } from 're-resizable';
import ContentContainer from '../../parts/ContentContainer';

const useStyles = makeStyles(theme => {
  return {
    root: {
      transition: 'all 0.2s',
      overflow: 'hidden',
      position: 'absolute',
      userSelect: 'none',
      left: 0,
      width: 'auto',
      height: 'auto',
    },
    rootPC: {
      top: 0,
    },
    rootMobile: {
      height: '100%',
      width: '100%',
      bottom: 0,
    },
    rootMinPC: {
      top: ({ height }) => -height - 40,
    },
    rootMinMobile: {
      bottom: 'calc(-100% - 40px)',
    },
  };
});

const Menu = inject('yluiStore')(
  observer(({ yluiStore }) => {
    const storeMenu = yluiStore.storeMenu;
    const { width, height } = storeMenu;
    const classes = useStyles({ height, isMobile: yluiStore.isMobile });
    return (
      <Box
        className={classNames(classes.root, {
          [classes.rootPC]: !yluiStore.isMobile,
          [classes.rootMobile]: yluiStore.isMobile,
          [classes.rootMinPC]: storeMenu.min && !yluiStore.isMobile,
          [classes.rootMinMobile]: storeMenu.min && yluiStore.isMobile,
        })}
        style={{
          ...yluiStore.storeTheme.styleBgSidebar,
          ...yluiStore.storeTheme.styleColorWhite1,
          ...yluiStore.storeTheme.styleBoxShadow,
          zIndex: storeMenu.zIndex,
        }}
      >
        <Resizable
          minWidth={100}
          minHeight={200}
          size={yluiStore.isMobile ? yluiStore.frameSize : { width, height }}
          onResizeStart={() => {
            yluiStore.setFrameMovingState(true);
          }}
          onResizeStop={(e, direction, ref, d) => {
            storeMenu.width += d.width;
            storeMenu.height += d.height;
            yluiStore.setFrameMovingState(false);
          }}
          enable={
            !yluiStore.isMobile
              ? {
                  top: false,
                  right: true,
                  bottom: true,
                  left: false,
                  topRight: false,
                  bottomRight: true,
                  bottomLeft: false,
                  topLeft: false,
                }
              : {
                  top: false,
                  right: false,
                  bottom: false,
                  left: false,
                  topRight: false,
                  bottomRight: false,
                  bottomLeft: false,
                  topLeft: false,
                }
          }
        >
          <ContentContainer>{storeMenu.content}</ContentContainer>
        </Resizable>
      </Box>
    );
  })
);

export default Menu;
