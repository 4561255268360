import { observable, action, computed } from 'mobx';

class Sidebar {
  @observable zIndex = 0;
  @observable content = null;
  @observable min = true;
  @computed get actived() {
    return !this.min;
  }
  constructor(root) {
    this.root = root;
  }
  @action active = () => {
    const newIndex = this.root.genId();
    this.zIndex = newIndex;
    this.min = false;
    this.root.storeMenu.hide();
  };
  @action hide = () => {
    this.min = true;
  };
  @action setContent = content => {
    this.content = content;
  };
}

export default Sidebar;
