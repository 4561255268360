import React from 'react';
import loadScript from 'load-script';
import { observable, action, computed } from 'mobx';
import Task from './task';
import StoreMenu from './menu';
import StoreSidebar from './sidebar';
import StoreWallpaper from './wallpaper';
import StoreTheme from './theme';
import iconWin10 from '../asset/icon/icons8-windows-10-48.png';
import getRandomWallpaperUrl from '../asset/wallpaper';

const Demo = React.lazy(() => import('../components/Demo'));
const NormalWindow = React.lazy(() =>
  import('../components/Demo/NormalWindow')
);

class StoreRoot {
  currentId = 0;
  openCounter = 0;
  storeTheme = new StoreTheme(this);
  storeWallpaper = new StoreWallpaper(this);
  storeSidebar = new StoreSidebar(this);
  storeMenu = new StoreMenu(this);
  @observable framesSize = {
    width: document.body.clientWidth - 2,
    height: document.body.clientHeight - 27,
  };
  @observable isMobile = document.body.clientWidth - 8 <= 768;
  @observable tasks = [];
  @observable activeTaskId = 0;
  @observable frameMoving = false;
  @computed get rootStyles() {
    return {
      backgroundImage: this.wallpaper
        ? `url(${this.wallpaper})`
        : 'linear-gradient(45deg, #03A9F4, #3F51B5)',
    };
  }

  constructor({ demo = false, debug = false }) {
    if (debug) {
      this.enableDebug();
    }
    if (demo) {
      this.initDemo();
    }
  }

  enableDebug() {
    loadScript('//cdn.jsdelivr.net/npm/eruda', (err, script) => {
      if (err) {
        console.error(err);
      } else {
        window.eruda.init();
      }
    });
  }

  initDemo() {
    setTimeout(() => {
      this.storeMenu.setIcon(iconWin10);
      this.storeMenu.setContent(<Demo />);
    }, 100);
    this.setWallpaper(getRandomWallpaperUrl());
    setInterval(() => {
      const newImage = getRandomWallpaperUrl();
      this.setWallpaper(newImage);
    }, 20000);
  }

  genId() {
    this.currentId += 1;
    return this.currentId;
  }

  @action handleShortCut = ({ type, data }) => {
    if (type === 'toggleWindow') {
      const task = this.tasks[data - 1];
      if (task) {
        if (task.min) {
          task.active();
        } else {
          task.minimize();
        }
      }
    }
  };

  @action setWallpaper(url) {
    return this.storeWallpaper.setWallpaper(url);
  }

  @action setFramesSize({ width, height }) {
    this.framesSize = {
      width,
      height,
    };
    this.isMobile = width <= 768;
  }

  // 返回新窗口的id
  @action open({
    content,
    title = '-',
    max = false,
    min = false,
    width,
    height,
    top,
    icon,
    left,
    closeable = true,
    resizeable = true,
    dragable = true,
    singleton = '',
  }) {
    if (singleton) {
      const singletonTask = this.tasks.find(t => t.singleton === singleton);
      if (singletonTask) {
        singletonTask.active();
        return singletonTask.id;
      }
    }
    const i = this.genId();
    this.openCounter += 1;
    let realWidth;
    if (width) {
      realWidth = width;
    } else {
      realWidth = this.framesSize.width * 0.8;
      if (realWidth > 1280) {
        realWidth = 1280;
      }
    }

    let realHeight;
    if (height) {
      realHeight = height;
    } else {
      realHeight = this.framesSize.height * 0.8;
      if (realHeight > 720) {
        realHeight = 720;
      }
    }

    let realTop;
    if (top !== undefined) {
      realTop = top;
    } else {
      realTop =
        parseInt((this.framesSize.height - realHeight) / 3) +
        (this.openCounter % 12) * 12;
      if (realTop + realHeight > this.framesSize.height) {
        realTop = this.framesSize.height - realHeight;
      }
    }
    let realLeft;
    if (left !== undefined) {
      realLeft = left;
    } else {
      realLeft =
        parseInt((this.framesSize.width - realWidth) / 3) +
        (this.openCounter % 12) * 12;
      if (realLeft + realWidth > this.framesSize.width) {
        realLeft = this.framesSize.width - realWidth;
      }
    }
    const task = new Task({
      root: this,
      id: i,
      zIndex: i,
      title,
      icon,
      content,
      width: realWidth,
      height: realHeight,
      left: realLeft,
      top: realTop,
      closeable,
      resizeable,
      dragable,
      singleton,
      min,
    });
    const realMax = max === undefined ? this.isMobile : max;
    if (realMax) {
      task.maximise();
    }
    this.tasks.push(task);
    min || this.activeTask(i);
    return task;
  }

  @action activeTask = id => {
    const task = this.tasks.find(t => t.id === id);
    if (task) {
      task._active();
      this.activeTaskId = id;
      this.closeSystemFrame();
    }
  };

  @action activeLastTask = lastHideTaskId => {
    const tasks = this.tasks.filter(t => lastHideTaskId !== t.id && !t.min);
    if (!tasks.length) {
      this.activeTaskId = null;
      return;
    }
    const sorted = tasks.sort((a, b) => {
      return b.zIndex - a.zIndex;
    });
    this.activeTask(sorted[0].id);
  };

  @action minimizeTask = id => {
    const task = this.tasks.find(t => t.id === id);
    if (task) {
      task._minimize(true);
      this.activeLastTask(id);
    }
  };

  @action closeTask = id => {
    const taskIndex = this.tasks.findIndex(t => t.id === id);
    if (taskIndex !== -1) {
      const task = this.tasks[taskIndex];
      task.handleOnClose();
      this.tasks.splice(taskIndex, 1);

      this.activeLastTask(id);
    }
  };

  @action setFrameMovingState(isMoving) {
    this.frameMoving = isMoving;
  }

  handleDesktopClick = () => {
    this.closeSystemFrame();
  };

  @action closeSystemFrame = () => {
    // 关闭所有的系统弹窗
    this.storeMenu.hide();
    this.storeSidebar.hide();
  };
}

export default StoreRoot;
