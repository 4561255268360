import React, { Suspense } from 'react';
import { observer, inject, Provider } from 'mobx-react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Title from './Title.js';
import IframeClickable from '../../parts/IframeClickable';
import { Scrollbars } from 'react-custom-scrollbars';
import ErrorBoundary from '../../ErrorBoundary/index.js';

const useStyles = makeStyles(theme => ({
  iframe: {
    border: 'none',
    width: '100%',
    height: '100%',
  },
  mask: {
    position: 'absolute',
    zIndex: 10,
    bottom: 0,
    height: 'calc(100% - 26px)',
    width: '100%',
  },
  iframeWarpper: {
    '-webkit-overflow-scrolling': 'touch',
  },
}));

const BoxContent = inject('yluiStore')(
  observer(({ dragClassName, yluiStore, task }) => {
    const classes = useStyles();
    const { content, _setRefIframe } = task;
    const ScrollbarsContainer = yluiStore.isMobile ? Box : Scrollbars;
    const styleContent = yluiStore.storeTheme.styleBgFrameContent;
    return (
      <Box height="100%" display="flex" flexDirection="column">
        <Title dragClassName={dragClassName} task={task} />
        {yluiStore.frameMoving && <Box className={classes.mask} />}
        {typeof content === 'string' ? (
          <Box
            flexGrow={1}
            // height={100}经验修复在wechat上高度为0的bug
            height={100}
            className={classes.iframeWarpper}
            style={styleContent}
          >
            <IframeClickable
              getRef={_setRefIframe}
              onInferredClick={task.active}
              className={classes.iframe}
              src={content}
            />
          </Box>
        ) : (
          <Box flexGrow={1} height={100} style={styleContent}>
            {/* // height={100}经验修复在wechat上高度为0的bug */}
            <ScrollbarsContainer
              style={{ height: '100%' }}
              overflow="auto"
              autoHide
            >
              <Box style={{ height: '100%' }}>
                <Provider yluiTask={task}>
                  <ErrorBoundary>
                    <Suspense fallback={null}>{content}</Suspense>
                  </ErrorBoundary>
                </Provider>
              </Box>
            </ScrollbarsContainer>
          </Box>
        )}
      </Box>
    );
  })
);

export default BoxContent;
