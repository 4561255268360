import React, { useState, useEffect, useCallback } from 'react';
import { observer, inject } from 'mobx-react';
import { IconButton, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import IconClose from '@material-ui/icons/Close';
import Icon from '../parts/Icon';
import TextTitle from '../parts/TextTitle';

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      transition: 'all 0.2s',
      overflow: 'hidden',
      minHeight: theme.spacing(4),
      padding: `0 ${theme.spacing(1.5)}px`,
      position: 'relative',
      userSelect: 'none',
      maxWidth: 200,
      '&:hover $iconButton': {
        opacity: 1,
        transform: 'scale(1)',
      },
      '&:hover $iconButton$iconButtonDisabled': {
        opacity: 0,
      },
    },
    rootInit: {
      padding: '0 0',
      maxWidth: 0,
    },
    mask: {
      position: 'absolute',
      zIndex: 0,
      bottom: 0,
      left: 0,
      height: '100%',
      width: '100%',
      transition: 'all 0.2s',
    },
    rootPureColorActived: {
      backgroundColor: 'white',
    },
    iconButton: {
      opacity: 1,
      transition: 'all 0.2s',
      transform: 'scale(0)',
      marginLeft: theme.spacing(0.5),
    },
    icon: {
      fontSize: 12,
      color: 'white',
    },
    iconButtonDisabled: { opacity: 0 },
  };
});

const Status = inject('yluiStore')(
  observer(
    ({
      yluiStore,
      task: { id, title, minimize, active, actived, closeable, close, icon },
    }) => {
      const classes = useStyles();
      const iconDisabled = !closeable;
      const [isInit, setIsInit] = useState(true);
      useEffect(() => {
        setIsInit(false);
      }, []);
      return (
        <Box
          onContextMenu={close}
          style={{
            ...yluiStore.storeTheme.styleBgTaskBar,
            ...yluiStore.storeTheme.styleColorWhite1,
          }}
          className={classNames(classes.root, {
            [classes.rootPureColorActived]: actived,
            [classes.rootInit]: isInit,
          })}
          onClick={() => {
            if (actived) {
              minimize();
            } else {
              active();
            }
          }}
        >
          <Box
            className={classNames(classes.mask)}
            style={{
              ...(actived ? yluiStore.storeTheme.styleBackGroundLighter1 : {}),
            }}
          ></Box>
          <Box display="flex" mr={yluiStore.isMobile ? 0 : 1}>
            <Icon src={icon} id={`frame-${id}-icon`} size={18} />
          </Box>
          {!yluiStore.isMobile && (
            <>
              <TextTitle
                text={title}
                style={yluiStore.storeTheme.styleColorWhite1}
              />
              <IconButton
                disabled={iconDisabled}
                aria-label="apps"
                size="small"
                className={classNames(classes.iconButton, {
                  [classes.iconButtonDisabled]: iconDisabled,
                })}
                onClick={() => {
                  close();
                }}
              >
                <IconClose className={classes.icon} size="small" />
              </IconButton>
            </>
          )}
        </Box>
      );
    }
  )
);

export default Status;
