import React from 'react';
import { inject, observer } from 'mobx-react';
import { Card, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Status from './Status.js';

const useStyles = makeStyles(theme => {
  return {
    placeholder: {
      display: 'flex',
      alignItems: 'center',
      boxShadow: 'none',
      transition: 'all 0.2s',
      minHeight: theme.spacing(4),
      padding: `0 ${theme.spacing(1.5)}px`,
      boxShadow: 'none',
      position: 'relative',
      userSelect: 'none',
      flexGrow: 1,
      borderRadius: 0,
    },
  };
});

const StatusHolder = inject('yluiStore')(
  observer(({ yluiStore }) =>
    yluiStore.tasks.map(t => <Status key={t.id} task={t} />)
  )
);

const StatusBar = inject('yluiStore')(
  observer(({ yluiStore }) => {
    const classes = useStyles();
    return (
      <Box
        flexGrow={1}
        height="100%"
        display="flex"
        alignItems="center"
        overflow="hidden"
        position="relative"
        flexDirection={yluiStore.isMobile ? 'row-reverse' : 'row'}
      >
        <StatusHolder />
        <Card
          className={classes.placeholder}
          style={yluiStore.storeTheme.styleBgTaskBar}
        ></Card>
      </Box>
    );
  })
);

export default StatusBar;
