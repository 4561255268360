import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import IconEmpty from '@material-ui/icons/ChatBubbleOutline';
import IconNotEmpty from '@material-ui/icons/Sms';

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      transition: 'all 0.2s',
      overflow: 'hidden',
      minHeight: theme.spacing(4),
      padding: `0 ${theme.spacing(1.5)}px`,
      position: 'relative',
      userSelect: 'none',
      maxWidth: 200,
    },
    rootInit: {
      padding: '0 0',
      maxWidth: 0,
    },
    mask: {
      position: 'absolute',
      zIndex: 0,
      bottom: 0,
      left: 0,
      height: '100%',
      width: '100%',
      transition: 'all 0.2s',
    },
    rootPureColorActived: {
      backgroundColor: 'white',
    },
    icon: {
      fontSize: 12,
      color: 'white',
    },
  };
});

const Btn = inject('yluiStore')(
  observer(({ yluiStore }) => {
    const classes = useStyles();
    const [isInit, setIsInit] = useState(true);
    useEffect(() => {
      setIsInit(false);
    }, []);
    const { storeSidebar } = yluiStore;
    const { actived } = storeSidebar;
    return (
      <Box
        style={{
          ...yluiStore.storeTheme.styleBgTaskBar,
          ...yluiStore.storeTheme.styleColorWhite1,
          ...(yluiStore.isMobile
            ? {
                borderRight: `1px solid ${yluiStore.storeTheme.colorLightWhite}`,
              }
            : {
                borderLeft: `1px solid ${yluiStore.storeTheme.colorLightWhite}`,
              }),
        }}
        className={classNames(classes.root, {
          [classes.rootPureColorActived]: actived,
          [classes.rootInit]: isInit,
        })}
        onClick={() => {
          if (actived) {
            storeSidebar.hide();
          } else {
            storeSidebar.active();
          }
        }}
      >
        <Box
          className={classNames(classes.mask)}
          style={{
            ...(actived ? yluiStore.storeTheme.styleBackGroundLighter1 : {}),
          }}
        ></Box>
        <Box display="flex">
          <IconEmpty style={{ fontSize: 18 }} />
        </Box>
      </Box>
    );
  })
);

export default Btn;
