import React, { useCallback, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { Resizable } from 're-resizable';
import BoxContent from './Content';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  transitionRestore: {
    transition: 'all 0.2s',
  },
}));

const BoxResize = inject('yluiStore')(
  observer(
    ({
      dragClassName,
      task,
      handleMovingStart,
      handleMovingStop,
      yluiStore,
    }) => {
      const classes = useStyles();
      const {
        top,
        left,
        width,
        height,
        setPosition,
        setSize,
        clearMaxWhenResize,
        resizeable,
        onTransitionRestore,
      } = task;
      const [originPosition, setOriginPosition] = useState({ top: 0, left: 0 });
      const handleResizeStart = useCallback(() => {
        handleMovingStart();
        setOriginPosition({
          top: top,
          left: left,
        });
      }, [top, left, handleMovingStart]);
      const handleResize = useCallback(
        (e, direction, ref, d) => {
          if (['left', 'bottomLeft'].includes(direction)) {
            setPosition({
              left: originPosition.left - d.width,
              top: originPosition.top,
            });
          }
          if (['top', 'topRight'].includes(direction)) {
            setPosition({
              left: originPosition.left,
              top: originPosition.top - d.height,
            });
          }
          if (direction === 'topLeft') {
            setPosition({
              left: originPosition.left - d.width,
              top: originPosition.top - d.height,
            });
          }
        },
        [originPosition, setPosition]
      );
      const handleResizeStop = useCallback(
        (e, direction, ref, d) => {
          setSize({
            width: width + d.width,
            height: height + d.height,
          });
          if (d.width !== 0 || d.height !== 0) {
            clearMaxWhenResize();
          }
          handleMovingStop();
        },
        [setSize, clearMaxWhenResize, handleMovingStop, width, height]
      );
      return (
        <Resizable
          minWidth={200}
          minHeight={150}
          className={classNames({
            [classes.transitionRestore]: onTransitionRestore,
          })}
          enable={
            resizeable && !yluiStore.isMobile
              ? {
                  top: true,
                  right: true,
                  bottom: true,
                  left: true,
                  topRight: true,
                  bottomRight: true,
                  bottomLeft: true,
                  topLeft: true,
                }
              : {
                  top: false,
                  right: false,
                  bottom: false,
                  left: false,
                  topRight: false,
                  bottomRight: false,
                  bottomLeft: false,
                  topLeft: false,
                }
          }
          size={yluiStore.isMobile ? yluiStore.framesSize : { width, height }}
          onResizeStart={handleResizeStart}
          onResize={handleResize}
          onResizeStop={handleResizeStop}
        >
          {(yluiStore.frameMoving || onTransitionRestore) && (
            <Box
              position="absolute"
              top={0}
              left={0}
              width="100%"
              height="100%"
              zIndex={1}
            ></Box>
          )}
          <BoxContent task={task} dragClassName={dragClassName} />
        </Resizable>
      );
    }
  )
);

export default BoxResize;
