import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
    color: 'black',
    cursor: 'default',
    fontWeight: 300,
  },
}));
const TextTitle = ({ text, style }) => {
  const classes = useStyles();

  return (
    <Typography
      noWrap
      align="left"
      className={classes.root}
      style={style}
      display="block"
      variant="caption"
    >
      {text}
    </Typography>
  );
};

export default TextTitle;
