import React from 'react';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';
import { Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconClose from '@material-ui/icons/Close';
import IconMax from '@material-ui/icons/Fullscreen';
import IconMin from '@material-ui/icons/Minimize';
import IconRestore from '@material-ui/icons/FullscreenExit';
import Icon from '../../parts/Icon';
import TextTitle from '../../parts/TextTitle';

const useStyles = makeStyles(theme => ({
  titleBar: {
    padding: 4,
  },
  icon: {
    fontSize: 14,
  },
  iconButton: {
    marginLeft: theme.spacing(0.5),
    '&.MuiIconButton-root': {
      color: 'black',
    },
  },
  iconBox: {
    display: 'flex',
    marginRight: 2,
    width: 20,
    height: 20,
    overflow: 'hidden',
  },
}));

const Title = inject('yluiStore')(
  observer(
    ({
      dragClassName,
      yluiStore,
      task: {
        icon,
        id,
        title,
        maximise,
        restore,
        max,
        closeable,
        resizeable,
        minimize,
        actived,
      },
    }) => {
      const classes = useStyles();
      const propsIconButton = {
        'aria-label': 'apps',
        size: 'small',
        className: classNames(classes.iconButton),
        style: {
          ...(actived
            ? yluiStore.storeTheme.styleColorWhite1
            : yluiStore.storeTheme.styleColorBlack1),
        },
      };
      return (
        <Box
          display="flex"
          alignItems="center"
          className={classes.titleBar}
          style={{
            ...(actived
              ? yluiStore.storeTheme.styleBgFrameTitleActived
              : yluiStore.storeTheme.styleBgFrameTitle),
          }}
        >
          <Box
            flexGrow={1}
            display="flex"
            alignItems="center"
            overflow="hidden"
            className={dragClassName}
            onDoubleClick={() => {
              if (!resizeable) return;
              if (max) {
                restore();
              } else {
                maximise();
              }
            }}
          >
            <Box mr={0.5} display="flex">
              <Icon size={18} src={icon} />
            </Box>
            <TextTitle
              text={title}
              style={{
                ...(actived
                  ? yluiStore.storeTheme.styleColorWhite1
                  : yluiStore.storeTheme.styleColorBlack1),
              }}
            />
          </Box>
          <IconButton
            {...propsIconButton}
            onClick={e => {
              minimize();
              e.stopPropagation();
            }}
          >
            <IconMin size="small" className={classes.icon} />
          </IconButton>
          {!yluiStore.isMobile &&
            resizeable &&
            (max ? (
              <IconButton {...propsIconButton} onClick={restore}>
                <IconRestore size="small" className={classes.icon} />
              </IconButton>
            ) : (
              <IconButton {...propsIconButton} onClick={maximise}>
                <IconMax size="small" className={classes.icon} />
              </IconButton>
            ))}
          {closeable && (
            <IconButton
              {...propsIconButton}
              onClick={() => {
                yluiStore.closeTask(id);
              }}
            >
              <IconClose size="small" className={classes.icon} />
            </IconButton>
          )}
        </Box>
      );
    }
  )
);

export default Title;
