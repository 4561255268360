import React from 'react';
import 'typeface-roboto';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'mobx-react';

import { createYluiStore, Ylui } from '@src/ylui2';
import RootStore from '@src/stores/index';

const yluiStore = createYluiStore({ demo: true, debug: false });
const store = new RootStore(yluiStore);

function App() {
  return (
    <Provider store={store}>
      <React.Fragment>
        <Router>
          <Ylui store={yluiStore} />
        </Router>
      </React.Fragment>
    </Provider>
  );
}

export default App;
