// https://github.com/springload/react-iframe-click/
import React, { useEffect, useCallback } from 'react';

export default function Iframe(props) {
  let current;

  const iframeCallbackRef = useCallback(node => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    current = node;
    props.getRef && props.getRef(node);
  }, []);

  useEffect(() => {
    const onBlur = () => {
      if (
        document.activeElement &&
        document.activeElement.nodeName.toLowerCase() === 'iframe' &&
        current &&
        current === document.activeElement
      ) {
        // infer a click event
        props.onInferredClick(current);
      }
    };

    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('blur', onBlur);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { onInferredClick, getRef, ...iframeProps } = props;

  return (
    <iframe {...iframeProps} title="iframeClickable" ref={iframeCallbackRef} />
  );
}
