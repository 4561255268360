import { observable, computed } from 'mobx';
// import Color from 'color';

function isSupportCss(styleName) {
  const div = document.createElement('div');
  return div.style[styleName] !== undefined;
}

const styleGlass = { backdropFilter: 'blur(20px)' };

class StoreTheme {
  isBackdropFilterSupported = isSupportCss('backdrop-filter');
  @observable themeColor = 'rgb(0,122,204)';
  colorLightWhite = 'rgb(255, 255, 255, 0.3)';

  @computed get useBackdropFilterComputed() {
    return this.isBackdropFilterSupported;
  }

  // 任务栏背景样式
  @computed get styleBgTaskBar() {
    return this.useBackdropFilterComputed
      ? {
          ...styleGlass,
          backgroundColor: 'rgba(32,32,32,0.7)',
        }
      : {
          backgroundColor: 'rgba(32,32,32,1)',
        };
  }

  // 开始菜单和侧边栏背景样式
  @computed get styleBgSidebar() {
    return this.useBackdropFilterComputed
      ? {
          ...styleGlass,
          backgroundColor: 'rgba(42,42,42,0.7)',
        }
      : {
          backgroundColor: 'rgba(42,42,42,1)',
        };
  }

  // 窗体标题栏背景样式
  @computed get styleBgFrameTitleActived() {
    return {
      backgroundColor: this.themeColor,
    };
  }

  // 窗体标题栏背景样式
  @computed get styleBgFrameTitle() {
    return {
      backgroundColor: 'white',
    };
  }

  // 窗体内容区域背景样式(不能用毛玻璃，掉帧太多)
  @computed get styleBgFrameContent() {
    return {
      backgroundColor: 'rgba(255,255,255,1)',
    };
  }

  styleColorWhite1 = { color: 'rgba(255,255,255,1)' };
  styleColorWhite2 = { color: 'rgba(240,240,240,1)' };
  styleColorBlack1 = { color: 'rgba(0,0,0,1)' };
  styleColorBlack2 = { color: 'rgba(32,32,32,1)' };
  styleBackGroundLighter1 = {
    backgroundColor: 'rgba(255,255,255,0.2)',
  };
  styleBackGroundLighter2 = {
    backgroundColor: 'rgba(255,255,255,0.4)',
  };
  styleBackGroundDarken1 = {
    backgroundColor: 'rgba(0,0,0,0.2)',
  };
  styleBackGroundDarken2 = {
    backgroundColor: 'rgba(0,0,0,0.4)',
  };
  styleBoxShadow = {
    boxShadow: `
    0px 2px 1px -1px rgba(0,0,0,0.2),
    0px 1px 1px 0px rgba(0,0,0,0.14),
    0px 1px 3px 0px rgba(0,0,0,0.12),
    0px 0px 8px 1px rgba(0,0,0,0.14)`,
  };
}

export default StoreTheme;
