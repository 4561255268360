import React, { Suspense } from 'react';
import { Box } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
import ErrorBoundary from '@src/ylui2/components/ErrorBoundary';

const ContentContainer = ({ children, ...props }) => {
  return (
    <Box width="100%" height="100%" {...props}>
      <Scrollbars style={{ height: '100%' }} overflow="auto" autoHide>
        <Box style={{ height: '100%' }}>
          <ErrorBoundary>
            <Suspense fallback={null}>{children}</Suspense>
          </ErrorBoundary>
        </Box>
      </Scrollbars>
    </Box>
  );
};

export default ContentContainer;
