import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Box from '@material-ui/core/Box';
import IconImage from '../../asset/icon/icons8-errorcloud-64.png';
import Icon from '../parts/Icon';

const MyFallbackComponent = ({ componentStack, error }) => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100%">
    <Box textAlign="center">
      <Icon size={64} src={IconImage} />
      <p>
        <strong>Oops! An error occured!</strong>
      </p>
    </Box>
  </Box>
);

export default ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={MyFallbackComponent}>
      {children}
    </ErrorBoundary>
  );
};
