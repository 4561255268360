import list from './list.json';

/**
 * 取随机数
 * @param {number} n 最小值
 * @param {number} m 最大值
 * @returns {number}
 */
function randomInt(n, m) {
  const c = m - n + 1;
  return Math.floor(Math.random() * c + n);
}

export default function getRandomWallpaperUrl() {
  const i = randomInt(0, list.length - 1);
  return require('./image/' + list[i]);
}
