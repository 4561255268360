import React, { useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { observer, inject } from 'mobx-react';
import Draggable from 'react-draggable';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BoxResize from './Resize';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    position: 'absolute',
    minWidth: theme.spacing(10),
    minHeight: theme.spacing(10),
    animationDuration: ({ isMobile }) => (isMobile ? '0.1s' : '0.2s'),
    transformOrigin: '0 0',
  },
  rootMin: {
    display: 'none',
  },
  titleBar: {},
  transitionRestore: {
    transition: 'all 0.2s',
  },
}));

const Frame = inject('yluiStore')(
  observer(({ yluiStore, task }) => {
    const {
      zIndex,
      top,
      left,
      drag,
      dragStop,
      min,
      dragable,
      active,
      onTransitionRestore,
    } = task;
    const classes = useStyles({ isMobile: yluiStore.isMobile });
    const [hide, setHide] = useState(true);
    const [animateOrigin, setAnimateOrigin] = useState({ x: 0, y: 0 });

    useEffect(() => {
      const domIcon = document.getElementById(`frame-${task.id}-icon`);
      if (domIcon) {
        setAnimateOrigin({
          x: domIcon.getBoundingClientRect().left * 1.4, // *1.4是经验公式
          y: domIcon.getBoundingClientRect().top,
        });
      }
      if (min) {
        // setHide(true); // 不直接隐藏，改为在动画结束后隐藏
      } else {
        setHide(false);
      }
    }, [min, setAnimateOrigin]);

    const handleMovingStart = useCallback(() => {
      yluiStore.setFrameMovingState(true);
      active();
    }, [yluiStore, active]);
    const handleMovingStop = useCallback(() => {
      yluiStore.setFrameMovingState(false);
    }, [yluiStore]);
    const handleDrag = useCallback(
      (e, d) => {
        drag({
          dx: d.deltaX,
          dy: d.deltaY,
        });
        handleMovingStart();
      },
      [drag]
    );
    const handleDragStop = useCallback(() => {
      handleMovingStop();
      dragStop();
    }, [handleMovingStop, dragStop]);

    const handleAnimationEnd = useCallback(() => {
      if (min) setHide(true);
    });

    return (
      <Draggable
        disabled={!dragable}
        handle={`.${classes.titleBar}`}
        bounds="parent"
        onDrag={handleDrag}
        onStop={handleDragStop}
        position={{
          x: left,
          y: top,
        }}
      >
        <Box
          className={classNames(classes.root, {
            [classes.rootMin]: hide,
            zoomIn: true,
            zoomOut: min,
            [classes.transitionRestore]: onTransitionRestore,
          })}
          onAnimationEnd={handleAnimationEnd}
          style={{
            zIndex,
            top: 0,
            left: 0,
            transformOrigin: `${animateOrigin.x}px ${animateOrigin.y}px`,
            ...yluiStore.storeTheme.styleBoxShadow,
          }}
        >
          <Box onMouseDown={active} onKeyDown={active} onTouchStart={active}>
            <BoxResize
              handleMovingStart={handleMovingStart}
              handleMovingStop={handleMovingStop}
              task={task}
              dragClassName={classes.titleBar}
            />
          </Box>
        </Box>
      </Draggable>
    );
  })
);

export default Frame;
