import React from 'react';
import { inject, observer } from 'mobx-react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Box } from '@material-ui/core';
import Menu from './Menu';
import Sidebar from './Sidebar';
import Frame from './Frame';

const Frames = inject('yluiStore')(
  observer(({ yluiStore }) => {
    return (
      <Box flexGrow={1} overflow="hidden" position="relative">
        <Box
          onClick={yluiStore.handleDesktopClick}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 0,
          }}
        >
          <AutoSizer style={{ width: '100%', height: '100%' }}>
            {({ height, width }) => {
              yluiStore.setFramesSize({ height, width });
            }}
          </AutoSizer>
        </Box>
        {yluiStore.tasks.map(t => (
          <Frame key={t.id} task={t} />
        ))}
        <Sidebar />
        <Menu />
      </Box>
    );
  })
);

export default Frames;
