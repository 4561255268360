import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const commonStyles = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  top: 0,
  left: 0,
  zIndex: -2,
  transition: 'opacity 2s',
};

const useStyles = makeStyles(theme => ({
  root: {
    ...commonStyles,
  },
  a: {
    ...commonStyles,
    opacity: 0,
  },
  b: {
    ...commonStyles,
    opacity: 0,
  },
  actived: {
    opacity: 1,
  },
  cover: {
    ...commonStyles,
    backgroundImage: ({ isMobile }) =>
      `linear-gradient(${
        isMobile ? 0 : 180
      }deg, rgba(0,0,0,0.6) 0, rgba(0,0,0,0.4) 32px, rgba(0,0,0,0.2) 64px, rgba(0,0,0,0.0) 128px, rgba(0,0,0,0.0))`,
    zIndex: -1,
  },
}));

const getBackgroundStyle = url => ({ backgroundImage: `url('${url}')` });

const Wallpaper = observer(
  ({
    isMobile,
    yluiStoreWallpaper: { wallpaperRealA, wallpaperRealB, activeA },
  }) => {
    const classes = useStyles({
      isMobile,
    });
    return (
      <Box className={classes.root}>
        {/* <Box className={classes.cover}></Box> */}
        <Box
          className={classNames(classes.a, { [classes.actived]: activeA })}
          style={getBackgroundStyle(wallpaperRealA)}
        />
        <Box
          className={classNames(classes.b, { [classes.actived]: !activeA })}
          style={getBackgroundStyle(wallpaperRealB)}
        />
      </Box>
    );
  }
);

export default Wallpaper;
