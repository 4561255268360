// eslint-disable-next-line no-unused-vars
import { default as YluiRootStore } from '@src/ylui2/stores/root';

class StoreRoot {
  /**
   * @param {YluiRootStore} yluiStore
   */
  constructor(yluiStore) {
    this.yluiStore = yluiStore;
  }
}

export default StoreRoot;
