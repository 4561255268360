import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
  },
}));

const Icon = ({ src, size = 24, style, className, ...others }) => {
  const classes = useStyles();
  return (
    <div
      style={{
        ...style,
        width: size,
        height: size,
        backgroundImage: `url(${src})`,
        backgroundSize: `${size}px ${size}px`,
      }}
      className={classNames(classes.root, {
        className,
      })}
      {...others}
    />
  );
};

export default Icon;
